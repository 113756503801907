<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BLink,
} from 'bootstrap-vue'

import itemsApi from '@/axios/api/items'
import messages from '@/libs/messages/toast'
import toast from '@/libs/toast'

export default {
  components: {
    BCard, BCardText, BCardBody, BCardTitle, BLink,
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['item'],

  methods: {
    deleteItem() {
      this.$bvModal.msgBoxConfirm('هل أنت متأكد من الحذف ؟', {
        size: 'sm',
        okTitle: 'موافق',
        cancelTitle: 'إلغاء',
        centered: true,
      })
        .then(value => {
          if (value === false) {
            return
          }

          this.delete()
        })
    },

    delete() {
      itemsApi.delete(this.item.slug).then(() => {
        toast.success(this.$bvToast, messages.success, messages.adDeleted)

        this.$emit('adDeleted', this.item.slug)
      }).catch(() => {
        toast.error(this.$bvToast, messages.error, messages.errorDetails)
      })
    },
  },
}
</script>

<template>
  <b-card
    :img-src="item.images[0].src"
    img-alt="card img"
  >
    <b-card-body class="px-0 py-0">
      <b-card-title class="mt-0 pt-0">
        {{ item.title | str_limit(20) }}
      </b-card-title>
      <b-card-text class="px-0 mx-0 pt-0 mt-0">
        <!-- <b-badge
          v-for="branch in item.branches"
          :key="branch.uid"
          variant="light-secondary"
          class="mr-1 mt-1"
        >
          {{ branch.name }}
        </b-badge> -->
      </b-card-text>

      <div class="d-flex justify-content-between border-top pt-2">
        <b-link
          :to="{name: 'edit-ad', params: {slug: item.slug}}"
          class="btn btn-sm btn-outline-primary"
        >
          <feather-icon
            icon="EditIcon"
          />
          تعديل
        </b-link>
        <button
          class="btn btn-sm btn-primary"
          @click.prevent="deleteItem()"
        >
          <feather-icon
            icon="TrashIcon"
          />
          حذف
        </button>
      </div>
    </b-card-body>
  </b-card>
</template>

<style lang="scss" scoped>
.card-img {
  height: 180px !important;
}
</style>
