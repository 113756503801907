<script>
import AdCard from '@/views/components/ads/AdCard.vue'
import itemsApi from '@/axios/api/items'
import store from '@/store'
import messages from '@/libs/messages/toast'
import { BSpinner, BLink } from 'bootstrap-vue'
import toast from '@/libs/toast'

export default {
  components: {
    AdCard,
    BSpinner,
    BLink,
  },
  data() {
    return {
      items: [],

      itemsLoaded: false,
    }
  },

  mounted() {
    const user = store.getters['user/user']

    itemsApi.getUserItems(user.uid)
      .then(response => {
        this.items = response.data.data
        this.itemsLoaded = true
      }).catch(error => {
        this.itemsLoaded = true
        console.log(error)
        toast.error(this.$bvToast, messages.error, messages.errorDetails)
      })
  },

  methods: {
    deleteAd(slug) {
      const index = this.items.findIndex(item => item.slug === slug)
      this.items.splice(index, 1)
    },
  },
}
</script>

<template>
  <div class="row">
    <div
      v-if="itemsLoaded === false"
      class="col-md-12 py-5 text-center"
    >
      <BSpinner
        large
        class="mx-1"
      />
    </div>

    <div
      v-if="itemsLoaded && items.length === 0"
      class="col-12"
    >
      <div class="alert alert-info p-2">
        لا نوجد إعلانات مضافة حاليا

        <b-link
          class="btn btn-sm btn-primary mx-2"
          :to="{name: 'create-ad'}"
        >
          إضافة إعلان
        </b-link>
      </div>
    </div>

    <div
      v-for="item in items"
      :key="item.uid"
      class="col-md-2 px-1"
    >
      <AdCard
        :item="item"
        @adDeleted="deleteAd"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
